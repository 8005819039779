/* Passe die Hauptfarben an */
.pretix-widget,
.pretix-widget * {
  color: #e2e8f0 !important; /* Allgemeine Textfarbe */
  font-family: "Arial", sans-serif !important; /* Schriftart ändern */
}

.pretix-widget h3,
.pretix-widget .pretix-widget-item-title {
  color: #edf2f7 !important; /* Überschriftfarben */
}

/* Anpassung der Buttons */
.pretix-widget button,
.pretix-widget input[type="button"],
.pretix-widget a.pretix-widget-button {
  background-color: #4a5568 !important;
  border-color: #2d3748 !important;
  color: #f7fafc !important;
  border-radius: 8px !important; /* Ecken abrunden */
}

.pretix-widget button:hover,
.pretix-widget input[type="button"]:hover,
.pretix-widget a.pretix-widget-button:hover {
  background-color: #2d3748 !important;
  border-color: #1a202c !important;
}
/* Anpassung der Tabellen */
.pretix-widget .pretix-widget-item-row,
.pretix-widget .pretix-widget-category {
  background-color: #1a202c !important;
  color: #e2e8f0 !important;
}

.pretix-widget .pretix-widget-category-description {
  color: #cbd5e0 !important;
}

/* Anpassung von Inputs */
.pretix-widget input[type="text"],
.pretix-widget input[type="number"],
.pretix-widget select {
  background-color: #2d3748 !important;
  color: #f7fafc !important;
  border-color: #4a5568 !important;
  border: 1px solid #4a5568 !important;
  border-radius: 5px !important;
}

/* Anpassung der Fehler- und Infoboxen */
.pretix-widget .pretix-widget-error-message,
.pretix-widget .pretix-widget-info-message {
  background-color: #2d3748 !important;
  border-color: #e53e3e !important; /* Farbe für Fehler */
  color: #f7fafc !important;
}

/* Tooltip-Styling */
.pretix-widget .pretix-widget-alert-box {
  background-color: #1a202c !important;
  color: #cbd5e0 !important;
  border: 1px solid #4a5568 !important;
  border-radius: 8px !important;
}

.pretix-widget-attribution {
  display: none;
}
